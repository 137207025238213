import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftDuplicato, RightDuplicato } from '../../components/pratiche-auto/duplicato-du/Duplicato';

const DuplicatoDu = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="duplicato-du" title="Duplicato DU o carta di circolazione">
                <LeftDuplicato />
                <RightDuplicato />
            </ContentPratica>
        </Layout>
    )
}

export default DuplicatoDu
