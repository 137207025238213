import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const LeftDuplicato = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
      </div>
      <div className="flex-col space-y-8">
        {/* Price Item for duplicato */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              50
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            Duplicato del DU o della carta di circolazione
          </p>
        </div>
      </div>
    </div>
  );
};

const RightDuplicato = () => {
  return (
    <div className="w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="">
        <div className="text-2xl text-black my-4">
          <p>
            In caso di smarrimento del documento unico o della carta di
            circolazione è necessario sporgere denuncia presso gli organi di
            polizia e richiedere l'emissione di un nuovo documento.
          </p>
          <p>
            Per richiedere il duplicato del DU o della carta di circolazione
            sono necessari i seguenti documenti:
          </p>
        </div>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">Certificato di proprietà</li>
          <li className="text-gray-700 text-xl">
            Documento d'identità del proprietario
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (in caso di proprietario extracomunitario)
          </li>
          <li className="text-gray-700 text-xl">
            Denuncia di smarrimento in originale
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftDuplicato, RightDuplicato };
